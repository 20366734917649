
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '性能底盘',
            //                     sort: 1
            //                 }, {
            //                     descName: '整体构造上采用模块化设计及组装，实现机身控制的动态调节，使整机具备极致的平稳性、支撑性与通过性，通过高精县挂实现高频减篇功能。不仅能够保障检测过程中画面的平稳和数据的准确，还能够更加稳定地承载防爆仓，进一步提升巡检性能。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [{
            //                 descName: '悬挂系统',
            //                 sort: 1
            //             }, {
            //                 descName: '采用2X4多连杆独立悬挂，提升产品行驶稳定性，解决高频振动，可以使车轮和地面尽最大可能保持垂直，减小车身的倾斜，维持轮胎的贴地性，满足产品在各类恶劣环境下的行驶，具备更高效的巡检作业能力。',
            //                 sort: 2
            //             }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '三代VCU',
            //                     sort: 1
            //                 }, {
            //                     descName: '更多类型的硬件接口能满足更多类型传感器的接入需求，通讯隔离型加抗干扰的硬件设计能更好的提高通讯的抗干扰能力，保证通讯质量。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '盲区安全触边',
            //                     sort: 1
            //                 }, {
            //                     descName: '解决产品侧向45°防撞条存在盲区的问题',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆散热系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '解决产品内部高温问题',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '离地间隙调节系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '整离地间隙高度',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '新型底盘电控系统',
            //                     sort: 1
            //                 }, {
            //                     descName: '满足驻坡需求，减小溜坡距离',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         }
            //     ],
            elmentList: [],
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}